<template>
  <div class="row full-width justify-center items-start no-wrap q-gutter-x-md">
    <!-- TODO: Remove pagination-label from inline, due to potential table performance issues? (look at docs) (also, see if anything else we may be doing that they say affects performance on components and maybe causing lagginess)
    -->
    <q-table
      ref="dataTable"
      :grid="grid"
      card-container-class="row q-col-gutter-md"
      :dense="dense"
      :loading="loading || internalLoading"
      :row-key="rowKey"
      :hide-bottom="hideBottom"
      :rows="loading && spinnerLoader ? [] : localRows"
      :columns="localColumns"
      :visible-columns="
        localColumns.filter(col => col.visible).map(col => col.name)
      "
      :filter="searchFilter"
      v-bind="{
        ...(clientSideFilterMethod && { filterMethod: clientSideFilterMethod })
      }"
      v-model:pagination="localPagination"
      :rows-per-page-options="
        rowsPerPageOptions.length > 0 ? rowsPerPageOptions : 0
      "
      :rows-per-page-label="
        rowsPerPageOptions.length > 0 ? 'Records per page:' : ' '
      "
      :pagination-label="
        (firstRowIndex, endRowIndex, totalRowsNumber) => {
          let label =
            numberFormatter(firstRowIndex) +
            ' - ' +
            numberFormatter(endRowIndex) +
            ' of ' +
            numberFormatter(totalRowsNumber);
          if (
            serverSide &&
            'rowsNumber' in pagination &&
            'totalRows' in pagination
          ) {
            label =
              'Displaying ' +
              label +
              ' (' +
              numberFormatter(pagination.totalRows) +
              ' Total)';
          }
          return label;
        }
      "
      binary-state-sort
      @request="props => handleQTableRequest(props)"
      color="primary"
      class="datatable col full-width sticky-header-table"
      :class="{
        'no-shadow': flat,
        '--sticky-column-table': stickyColumnTable
      }"
      :style="[
        height.length > 0 ? { 'max-height': height + ' !important' } : {},
        customStyles
      ]"
    >
      <template v-if="localRows.length === 0 || spinnerLoader" v-slot:loading>
        <div class="flex items-center justify-center q-px-md q-py-lg">
          <q-spinner color="primary" size="2.5em" />
        </div>
      </template>

      <template v-if="!hideTop" v-slot:top>
        <div
          class="full-width flex justify-between items-center q-header--bordered q-py-sm q-px-md"
          style="min-height: 60px"
        >
          <h6 v-if="title.length > 0" class="q-ma-none text-weight-bold">
            {{ title }}
          </h6>
          <div v-else>
            <slot name="title" />
          </div>
          <div class="flex no-wrap items-center q-gutter-x-md">
            <slot name="actions-additional" />
            <q-btn
              v-for="(action, index) in actions"
              v-bind:key="'dataTableAction_' + index"
              v-show="action.visible"
              class="q-pa-sm"
              :class="{
                'cursor-not-allowed':
                  action.key !== 'customize' && (loading || internalLoading)
              }"
              unelevated
              :ripple="action.key !== 'customize'"
              :icon="action.icon"
              :flat="action.key === 'customize' ? !sidePanel.show : true"
              :color="
                action.key === 'customize' && sidePanel.show
                  ? 'grey-3'
                  : 'grey-6'
              "
              text-color="grey-7"
              size="md"
              @click="
                () => {
                  if (action.key !== 'customize') {
                    if (!loading && !internalLoading) {
                      action.performAction();
                    }
                  } else {
                    action.performAction();
                  }
                }
              "
            >
              <q-tooltip anchor="top middle" self="bottom middle">
                {{
                  action.key === "refresh" && timeLastUpdated
                    ? "Last Updated: " + $dayjs(timeLastUpdated).format("lll")
                    : action.label
                }}
              </q-tooltip>
            </q-btn>
            <q-input
              v-if="searchable"
              dense
              filled
              clearable
              type="search"
              debounce="600"
              v-model="searchFilter"
              placeholder="Search..."
              class="self-stretch"
              @clear="
                () => {
                  searchFilter = '';
                }
              "
            >
              <template v-slot:prepend>
                <q-icon name="sym_r_search" />
              </template>
            </q-input>
          </div>
        </div>
        <slot name="top-additional" />
        <div
          v-show="numColumnsWithActiveFilters > 0"
          class="full-width flex justify-start items-center q-py-sm q-px-md q-header--bordered q-gutter-x-sm"
        >
          <p class="text-subtitle1 text-weight-medium q-ma-none">Filters:</p>
          <q-chip
            v-for="localColumn in localColumns"
            :key="'localColumnActiveFilterChip_' + localColumn.name"
            v-show="localColumn.activeFilterCount > 0"
            removable
            :ripple="false"
            color="grey-3"
            text-color="black"
            @remove="resetColumnFilters(localColumn)"
          >
            <span class="text-weight-medium" style="font-size: 97%">
              {{ localColumn.label }}
            </span>
            <span class="text-grey-8 q-ml-sm q-mr-xs">
              {{ generateColumnFilterPillLabel(localColumn) }}
              <q-tooltip v-if="localColumn.type === 'dimension'">
                <p class="text-weight-bold q-mb-xs">
                  <small>Active Filters</small>
                </p>
                <p
                  v-for="filterValue in localColumn.filterValues.in
                    .slice(0, 15)
                    .map(filterVal => {
                      return filtersByColumn[
                        localColumn.name
                      ].dimensionFilterOptions.find(
                        filterOption => filterOption.value == filterVal
                      ).label;
                    })
                    .sort(localColumn.sort)"
                  :key="
                    'filterPillTooltip_' + localColumn.name + '_' + filterValue
                  "
                  class="q-ma-none"
                >
                  {{ filterValue }}
                </p>
                <p
                  v-if="localColumn.filterValues.in.length > 15"
                  class="q-ma-none"
                >
                  And {{ localColumn.filterValues.in.length - 15 }} more...
                </p>
              </q-tooltip>
            </span>
          </q-chip>
        </div>
      </template>

      <template v-slot:header="props">
        <q-tr :props="props" class="datatable-column-headers">
          <!--<q-th v-for="col in props.cols" :key="col.name" :props="props" :class="col.classes ?? []" :style="col.style ?? []">
            <slot :name="col.name + '-column-label-prepend'" v-bind="col" />
            <span>{{ col.label }}</span>
            <slot :name="col.name + '-column-label-append'" v-bind="col" />
          </q-th>-->
          <q-th
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
            :style="col.style ?? []"
          >
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>

      <template v-if="grid" v-slot:item="props">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3">
          <q-card>
            <slot name="grid-item" v-bind="props.row" />
          </q-card>
        </div>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="(col, i) in props.cols" :key="col.name" :props="props" :class="col.classes ?? []">
            <div
              :class="
                expandableRows && i == 0
                  ? 'flex items-center justify-start'
                  : ''
              "
            >
              <q-btn
                v-if="expandableRows && i == 0"
                round
                flat
                dense
                @click="props.expand = !props.expand"
                :icon="
                  props.expand ? 'sym_r_expand_more' : 'sym_r_chevron_right'
                "
                :ripple="false"
                style="margin-left: -6px; margin-right: 6px"
              />

              <div>
                <slot :name="col.name + '-prepend'" v-bind="props.row" />
                <slot :name="col.name" v-bind="props.row" />

                <component
                  v-if="'isComponentField' in col && col.isComponentField"
                  :is="col.value.component"
                  v-bind="col.value.componentProperties"
                >
                  <div
                    v-if="col.value.componentBody.length > 0"
                    v-html="col.value.componentBody"
                  />
                </component>
                <span
                  v-else-if="
                    !$slots[col.name] &&
                      (col.value === 'undefined' || !col.value)
                  "
                  >-</span
                >
                <span v-else-if="!col.hideField" v-html="col.value" />

                <slot :name="col.name + '-append'" v-bind="props.row" />
              </div>
            </div>
          </q-td>
        </q-tr>
        <q-tr v-if="expandableRows" v-show="props.expand" :props="props">
          <q-td colspan="100%">
            <slot :name="'expanded-content'" v-bind="props.row" />
          </q-td>
        </q-tr>
      </template>

      <template
        v-if="pivotTable && Object.keys(totalsRow).length > 0"
        v-slot:bottom-row
      >
        <q-tr class="totals-row">
          <q-td
            v-for="(dimensionColumn, i) in localDimensionColumns.filter(
              dc => dc.visible
            )"
            :key="'totalsRowDimension_' + dimensionColumn.name"
            style="font-weight: bold; text-align: left; z-index: 4 !important;"
          >
            <template v-if="i === 0">
              Grand Totals
            </template>
          </q-td>
          <q-td
            v-for="metricColumn in localMetricColumns.filter(mc => mc.visible)"
            :key="'totalsRowMetric_' + metricColumn.name"
            style="text-align: right; z-index: 4 !important;"
            class="text-bold"
          >
            <span v-if="!totalsRow[metricColumn.name]">
              -
            </span>
            <span
              v-else-if="
                (totalsRow[metricColumn.name] &&
                  totalsRow[metricColumn.name].indexOf('-') > -1 &&
                  totalsRow[metricColumn.name] !== '-') ||
                  (metricColumn.name.includes('roas') &&
                    parseFloat(totalsRow[metricColumn.name]) < 1)
              "
              class="font-bold text-red"
            >
              {{ totalsRow[metricColumn.name] }}
            </span>
            <span v-else>
              {{ totalsRow[metricColumn.name] }}
            </span>
          </q-td>
        </q-tr>
      </template>
      <template v-else-if="localRows.length > 0 && customTotalsRow" v-slot:bottom-row>
        <q-tr class="totals-row">
          <slot name="custom-totals-row" />
        </q-tr>
      </template>
    </q-table>
    <q-card
      v-show="sidePanel.show"
      ref="dataTableSidePanel"
      class="overflow-hidden column no-wrap"
      style="width: 320px; height: 100%;"
    >
      <div>
        <div
          class="flex justify-between items-center q-py-sm q-px-md q-header--bordered"
          style="min-height: 60px"
        >
          <h6 class="q-ma-none">
            Customize
          </h6>
          <CloseButton @click="sidePanel.show = false" />
        </div>

        <q-tabs
          v-model="sidePanel.tab"
          class="text-grey q-header--bordered"
          no-caps
          dense
          active-color="primary"
          indicator-color="primary"
          align="justify"
        >
          <q-tab name="columns" label="Columns" :disable="!customizable">
            <q-tooltip v-if="!customizable">
              Not available on this table.
            </q-tooltip>
          </q-tab>
          <q-tab name="filters" label="Filters" :disable="!filterable">
            <q-tooltip v-if="!filterable">
              Not available on this table.
            </q-tooltip>
          </q-tab>
        </q-tabs>
      </div>

      <q-tab-panels
        keep-alive
        v-model="sidePanel.tab"
        style="flex: 1; overflow-y: auto"
      >
        <q-tab-panel name="columns" class="q-pa-none">
          <div v-if="localColumns.length > 0">
            <!--<div class="flex items-centers justify-between full-width q-px-sm q-pt-sm">
              <q-btn
                  dense
                  flat
                  color="primary"
                  class="q-px-sm"
              >
                <small>Select All</small>
              </q-btn>
              <q-btn
                  dense
                  flat
                  color="primary"
                  class="q-px-sm"
              >
                <small>DeSelect All</small>
              </q-btn>
            </div>-->
            <div
              v-for="columnsOrderSectionData in pivotTable
                ? [
                    {
                      header: 'Dimensions',
                      columnsDataKey: 'localDimensionColumns'
                    },
                    { header: 'Metrics', columnsDataKey: 'localMetricColumns' }
                  ]
                : [{ header: '', columnsDataKey: 'localColumns' }]"
              :key="columnsOrderSectionData.columnsDataKey"
            >
              <h6
                v-if="columnsOrderSectionData.header"
                class="q-px-md q-pb-sm q-pt-md"
              >
                {{ columnsOrderSectionData.header }}
              </h6>
              <q-list>
                <draggable
                  v-model="this[columnsOrderSectionData.columnsDataKey]"
                  item-key="name"
                  :disabled="isMobileUserAgent()"
                >
                  <template #item="{element}">
                    <q-item
                      :key="'localDimensionColumns_' + element.name"
                      tag="label"
                      dense
                      clickable
                    >
                      <q-item-section side class="q-pr-sm">
                        <q-checkbox
                          dense
                          v-model="element.visible"
                          @update:model-value="
                            visible => {
                              if (!visible) {
                                resetColumnFilters(element);
                              }
                            }
                          "
                        ></q-checkbox>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label
                          v-html="element.label"
                          :class="{
                            'text-bold': element.visible
                          }"
                          class="ellipsis"
                        ></q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-icon name="sym_r_drag_indicator" color="grey-5" />
                      </q-item-section>
                    </q-item>
                  </template>
                </draggable>
              </q-list>
            </div>
          </div>
        </q-tab-panel>

        <q-tab-panel name="filters" class="q-pa-none">
          <q-list v-if="filterable && localColumns.length > 0">
            <template
              v-for="localColumn in localColumns"
              :key="'filtersByColumn_' + localColumn.name"
            >
              <q-expansion-item
                v-if="localColumn.filterable"
                v-show="!pivotTable || localColumn.visible"
                :key="'filtersByColumnExpansionItem_' + localColumn.name"
                v-model="localColumn.filterContentExpanded"
                @update:model-value="
                  val => {
                    this.localColumns = localColumns.map(tempLocalColumn => {
                      tempLocalColumn.filterContentExpanded =
                        localColumn.name !== tempLocalColumn.name ? false : val;
                      return tempLocalColumn;
                    });
                  }
                "
                expand-separator
                dense
                :header-class="{
                  'text-weight-bold': localColumn.filterContentExpanded
                }"
              >
                <template v-slot:header>
                  <q-item-section>
                    {{ localColumn.label }}
                  </q-item-section>
                  <q-item-section side>
                    <q-badge
                      v-show="localColumn.activeFilterCount > 0"
                      rounded
                      color="primary"
                      :label="localColumn.activeFilterCount"
                      class="text-weight-bold"
                    />
                  </q-item-section>
                </template>
                <div v-if="Object.keys(filtersByColumn).length > 0">
                  <div v-if="localColumn.type === 'dimension'">
                    <div
                      v-if="
                        localColumn.visible &&
                          filtersByColumn[localColumn.name]
                            .dimensionFilterOptions.length === 0
                      "
                      class="text-center q-px-sm q-py-md"
                    >
                      <q-spinner color="primary" size="2.5em" />
                    </div>
                    <div v-else>
                      <div class="q-px-sm q-pt-sm">
                        <q-input
                          :key="
                            'filtersByColumnDimensionFilterOptionsSearch_' +
                              localColumn.name
                          "
                          v-model="
                            filtersByColumn[localColumn.name]
                              .dimensionFilterOptionsSearch
                          "
                          debounce="350"
                          @update:model-value="
                            val => {
                              filtersByColumn[
                                localColumn.name
                              ].dimensionFilterOptions = filtersByColumn[
                                localColumn.name
                              ].dimensionFilterOptions.map(filterOption => {
                                filterOption.visible =
                                  val === '' ||
                                  val === null ||
                                  filterOption.value
                                    .toString()
                                    .toLowerCase()
                                    .includes(val.toString().toLowerCase()) ||
                                  filterOption.label
                                    .toString()
                                    .toLowerCase()
                                    .includes(val.toString().toLowerCase());
                                return filterOption;
                              });
                            }
                          "
                          clearable
                          @clear="
                            () => {
                              filtersByColumn[
                                localColumn.name
                              ].dimensionFilterOptionsSearch = '';
                            }
                          "
                          dense
                          filled
                          bottom-slots
                          placeholder="Search..."
                          class="q-mb-sm"
                        >
                          <template v-slot:prepend>
                            <q-icon name="sym_r_search" />
                          </template>
                          <template v-slot:hint>
                            <span>
                              Showing
                              {{
                                numberFormatter(
                                  filtersByColumn[
                                    localColumn.name
                                  ].dimensionFilterOptions.filter(
                                    filterOption => filterOption.visible
                                  ).length
                                )
                              }}
                              out of
                              {{
                                numberFormatter(
                                  filtersByColumn[localColumn.name]
                                    .dimensionFilterOptions.length
                                )
                              }}
                            </span>
                          </template>
                        </q-input>
                      </div>
                      <div style="max-height: 200px; overflow-y: auto;">
                        <q-item
                          v-for="filterOption in filtersByColumn[
                            localColumn.name
                          ].dimensionFilterOptions"
                          :key="
                            'dimensionFilterOptions_' +
                              localColumn.name +
                              '_' +
                              filterOption.value
                          "
                          v-show="filterOption.visible"
                          dense
                          clickable
                          tag="label"
                        >
                          <q-item-section side class="q-pr-sm">
                            <q-checkbox
                              :key="
                                'dimensionFilterOptionsCheckBox_' +
                                  localColumn.name +
                                  '_' +
                                  filterOption.value
                              "
                              dense
                              v-model="localColumn.filterValues.in"
                              :val="filterOption.value"
                              @update:model-value="applyFiltersByColumn"
                            />
                          </q-item-section>
                          <q-item-section>
                            <q-item-label
                              v-html="filterOption.label"
                              :class="{
                                'text-bold': localColumn.filterValues.in.includes(
                                  filterOption.value
                                )
                              }"
                              class="ellipsis-2-lines"
                              :title="filterOption.label"
                            />
                          </q-item-section>
                        </q-item>
                      </div>
                      <div class="q-px-md q-py-sm text-right full-width">
                        <small
                          v-ripple
                          class="text-primary cursor-pointer relative-position q-pa-xs rounded-borders"
                          @click="resetColumnFilters(localColumn)"
                        >
                          Reset Filter
                        </small>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="localColumn.type === 'metric'">
                    <div
                      v-if="localColumn.metricType === 'number'"
                      class="row q-px-sm q-pt-sm q-col-gutter-x-sm"
                    >
                      <q-input
                        :key="
                          'MetricFilterOptionsGreaterThanInput_' +
                            localColumn.name
                        "
                        v-model="localColumn.filterValues.gt"
                        @update:model-value="applyFiltersByColumn"
                        type="number"
                        debounce="500"
                        label="Greater Than"
                        dense
                        filled
                        class="col-6"
                      />
                      <q-input
                        :key="
                          'MetricFilterOptionsLessThanInput_' + localColumn.name
                        "
                        v-model="localColumn.filterValues.lt"
                        @update:model-value="applyFiltersByColumn"
                        type="number"
                        debounce="500"
                        label="Less Than"
                        dense
                        filled
                        class="col-6"
                      />
                    </div>
                    <div
                      v-else-if="localColumn.metricType === 'date'"
                      class="row q-px-sm q-pt-sm q-col-gutter-x-sm"
                    >
                      <div class="col-6">
                        <DateSelector
                          :key="
                            'MetricFilterOptionsGreaterThanInput_' +
                              localColumn.name
                          "
                          v-model:date="localColumn.filterValues.gt"
                          @update:date="applyFiltersByColumn"
                          custom-label="After"
                        />
                      </div>
                      <div class="col-6">
                        <DateSelector
                          :key="
                            'MetricFilterOptionsLessThanInput_' +
                              localColumn.name
                          "
                          v-model:date="localColumn.filterValues.lt"
                          @update:date="applyFiltersByColumn"
                          custom-label="Before"
                        />
                      </div>
                    </div>
                    <div
                      v-else-if="localColumn.metricType === 'year'"
                      class="row q-px-sm q-pt-sm q-col-gutter-x-sm"
                    >
                      <q-select
                        :key="
                          'MetricFilterOptionsGreaterThanSelect_' +
                            localColumn.name
                        "
                        v-model="localColumn.filterValues.gt"
                        @update:model-value="applyFiltersByColumn"
                        :options="
                          Array.from({ length: 31 }, (_, i) =>
                            (new Date().getFullYear() - i).toString()
                          )
                        "
                        label="Greater Than"
                        dense
                        filled
                        class="col-6"
                      />
                      <q-select
                        :key="
                          'MetricFilterOptionsLessThanSelect_' +
                            localColumn.name
                        "
                        v-model="localColumn.filterValues.lt"
                        @update:model-value="applyFiltersByColumn"
                        :options="
                          Array.from({ length: 31 }, (_, i) =>
                            (new Date().getFullYear() - i).toString()
                          )
                        "
                        label="Less Than"
                        dense
                        filled
                        class="col-6"
                      />
                    </div>
                    <div class="q-px-md q-py-sm text-right full-width">
                      <small
                        v-ripple
                        class="text-primary cursor-pointer relative-position q-pa-xs rounded-borders"
                        @click="resetColumnFilters(localColumn)"
                      >
                        Reset Filter
                      </small>
                    </div>
                  </div>
                </div>
              </q-expansion-item>
            </template>
          </q-list>
        </q-tab-panel>
      </q-tab-panels>

      <div
        v-show="sidePanel.tab === 'filters'"
        class="full-width text-center q-pa-sm q-card--bordered"
      >
        <q-btn
          dense
          flat
          color="primary"
          class="q-px-sm"
          @click="
            () => {
              resetRows();
              resetFilters();
            }
          "
        >
          <small>Reset Filters</small>
        </q-btn>
      </div>
    </q-card>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { exportFile } from "quasar";
import LZString from "lz-string";
import _ from "lodash";
import DateSelector from "@/components/UI/DateSelector";
import CloseButton from "@/components/UI/CloseButton";

export default {
  name: "DataTable",
  components: { CloseButton, DateSelector, draggable },
  emits: [
    "updateRows",
    "refreshRows",
    "localRowsChanged",
    "filtersApplied",
    "filterReset"
  ],
  props: {
    tableKey: {
      type: String,
      required: true
    },
    rowKey: {
      type: String,
      default: "id"
    },
    title: {
      type: String,
      default: "",
      required: false
    },
    hideTop: {
      type: Boolean,
      default: false
    },
    hideBottom: {
      type: Boolean,
      default: false
    },
    stickyColumnTable: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    },
    totalsRow: {
      type: Object,
      default: () => {}
    },
    customTotalsRow: {
      type: Boolean,
      default: false
    },
    localRowsListener: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Object,
      default: () => ({
        sortBy: "",
        descending: false,
        page: 1,
        rowsPerPage: 10
      })
    },
    rowsPerPageOptions: {
      type: Array,
      default: () => [5, 10, 25]
    },
    pivotTable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    spinnerLoader: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    downloadable: {
      type: Boolean,
      default: false
    },
    refreshable: {
      type: Boolean,
      default: false
    },
    customizable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    },
    showSidePanel: {
      type: Boolean,
      default: false
    },
    serverSide: {
      type: Boolean,
      default: false
    },
    disableState: {
      type: Boolean,
      default: true
    },
    dimensionFilterOptionsByColumn: {
      type: Object,
      default: () => {}
    },
    timeLastUpdated: {
      type: String,
      default: ""
    },
    clientSideFilterMethod: {
      type: Function,
      required: false
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    expandableRows: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    grid: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: ""
    },
    customStyles: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      internalLoading: false,
      localPagination: this.pagination,
      localRows: this.rows,
      localColumns: this.columns.map(column => {
        if (!("type" in column)) {
          column.type = "dimension";
        }
        if (!("visible" in column)) {
          column.visible = true;
        }
        if (!("filterable" in column)) {
          column.filterable = this.filterable;
        }
        if (column.type === "metric" && !("metricType" in column)) {
          column.metricType = "number";
        }

        if (!("align" in column)) {
          column.align = column.type === "dimension" ? "left" : "right";
        }
        if (!("sortable" in column)) {
          column.sortable = true;
        }
        if (!("sort" in column)) {
          column.sort = (a, b) =>
            a.toLocaleString().localeCompare(b.toLocaleString());
        }
        if (!("format" in column)) {
          column.format = val => val;
        }
        if (!("field" in column)) {
          column.field = column.name;
        }
        if (!("hideField" in column)) {
          column.hideField = false;
        }

        if (!("filterValues" in column)) {
          column.filterValues = {
            in: [],
            notIn: [],
            gt: "",
            lt: ""
          };
        }
        if (!("activeFilterCount" in column)) {
          column.activeFilterCount = 0;
        }

        if (this.pivotTable && column.type === "dimension") {
          // If pivot table, add grey background to all "dimension" columns
          column.classes = "bg-grey-2";
        }

        return column;
      }),
      filtersByColumn: {},
      searchFilter: "",
      actions: [
        {
          key: "refresh",
          label: "Refresh",
          icon: "sym_r_refresh",
          visible: this.refreshable,
          performAction: () =>
            this.serverSide
              ? this.triggerUpdateRowsEvent()
              : this.$emit("refreshRows")
        },
        {
          key: "download",
          label: "Download",
          icon: "sym_r_file_download",
          visible: this.downloadable,
          performAction: () => this.exportTable()
        },
        {
          key: "customize",
          label: "Customize",
          icon: "sym_r_view_sidebar",
          visible: this.filterable || this.customizable,
          performAction: () => this.toggleSidePanel()
        }
      ],
      sidePanel: {
        show: this.showSidePanel,
        tab: this.customizable ? "columns" : "filters"
      }
    };
  },
  computed: {
    localDimensionColumns: {
      get() {
        return this.localColumns.filter(
          col => !col.type || col.type === "dimension"
        );
      },
      set(newValue) {
        this.localColumns = newValue.concat(this.localMetricColumns);
      }
    },
    localMetricColumns: {
      get() {
        return this.localColumns.filter(
          col => col.type && col.type === "metric"
        );
      },
      set(newValue) {
        this.localColumns = this.localDimensionColumns.concat(newValue);
      }
    },
    localColumnsByName() {
      return this.convertToObjectById(this.localColumns, "name");
    },
    numColumnsWithActiveFilters() {
      return this.localColumns.filter(lc => lc.activeFilterCount > 0).length;
    },
    localColumnsComputedCopy() {
      return JSON.parse(JSON.stringify(this.localColumns));
    }
  },
  watch: {
    loading(val) {
      this.internalLoading = val;
    },
    rows(val) {
      this.localRows = val;
      this.initializeFiltersByColumn();
      this.applyFiltersByColumn();
    },
    localRows(val) {
      if (this.localRowsListener) {
        this.$emit("localRowsChanged", val);
      }
    },
    pagination: {
      handler(val) {
        this.localPagination = val;
      },
      deep: true
    },
    localColumnsComputedCopy(newVal, oldVal) {
      if (this.serverSide) {
        // Check for columns visibility change (for pivot table only)
        if (this.pivotTable && this.customizable) {
          let newColumnsVisible = newVal
            .filter(newColumnA => newColumnA.visible)
            .map(newColumnB => newColumnB.name);
          let oldColumnsVisible = oldVal
            .filter(oldColumnA => oldColumnA.visible)
            .map(oldColumnB => oldColumnB.name);
          if (
            !this.$_.isEmpty(this.$_.xor(newColumnsVisible, oldColumnsVisible))
          ) {
            this.triggerUpdateRowsEvent();
            return;
          }
        }

        // Check for columns active filters change
        if (this.filterable) {
          let newColumnsFilters = newVal
            .filter(newColumnA => newColumnA.activeFilterCount > 0)
            .map(newColumnB => ({
              activeFilterCount: newColumnB.activeFilterCount,
              filterValues: newColumnB.filterValues
            }));
          let oldColumnsFilters = oldVal
            .filter(oldColumnA => oldColumnA.activeFilterCount > 0)
            .map(oldColumnB => ({
              activeFilterCount: oldColumnB.activeFilterCount,
              filterValues: oldColumnB.filterValues
            }));

          if (
            !this.$_.isEmpty(
              this.$_.xorWith(
                newColumnsFilters,
                oldColumnsFilters,
                this.$_.isEqual
              )
            ) ||
            newColumnsFilters.length != oldColumnsFilters.length
          ) {
            this.triggerUpdateRowsEvent();
            return;
          }
        }
      }
    }
  },
  created() {
    window.addEventListener("beforeunload", this.saveState);
  },
  mounted() {
    if (this.fullscreen && this.height.length === 0) {
      let maxHeightCss = "calc(100dvh - " + (this.$refs.dataTable.$el.getBoundingClientRect().top + 24) + "px)";
      this.$refs.dataTable.$el.style.maxHeight = maxHeightCss;
      this.$refs.dataTableSidePanel.$el.style.maxHeight = maxHeightCss;
    }
    this.loadState();
  },
  updated() {
    this.saveState();
  },
  methods: {
    triggerUpdateRowsEvent() {
      this.internalLoading = true;
      this.emitUpdateRowsEvent();
    },
    emitUpdateRowsEvent: _.debounce(function() {
      this.$emit("updateRows", {
        columns: this.localColumns,
        pagination: this.localPagination,
        searchFilter: this.searchFilter
      });
      // TODO: Also rate limit API in order to block high frequency subsequent requests (resulting from "pass-through" events)
    }, 1000),
    loadState() {
      // Initializes localColumns

      // Only utilize the stored data, to re-order, init visibility, and set filters, if the column names have not changed.
      let localColumnNames = this.localColumns.map(
        localColumn => localColumn.name
      );

      // If state is coming from local storage...
      // Re-order localColumns
      if (this.customizable) {
        let localStorageColumns = localStorage.getItem(
          this.tableKey + "_localColumns"
        );
        if (localStorageColumns) {
          localStorageColumns = JSON.parse(localStorageColumns);
          let localStorageColumnNames = localStorageColumns.map(
            localStorageColumn => localStorageColumn.name
          );

          // Check columns names/structure, on match re-order, init visibilities, and set filter values as needed.
          if (
            localStorageColumnNames.length == localColumnNames.length &&
            localStorageColumnNames.every(localStorageColumnName =>
              localColumnNames.includes(localStorageColumnName)
            )
          ) {
            this.localColumns.sort(
              (a, b) =>
                localStorageColumnNames.indexOf(a.name) -
                localStorageColumnNames.indexOf(b.name)
            );
          }
        }
      }

      // If state is coming from url params..
      // Init visibility, filterValues, activeFilterCount on localColumns
      // Also, init the pagination & searchFilter on the table
      let urlParams = new URLSearchParams(window.location.search);
      let urlParamTableState = urlParams.get(this.tableKey + "_state");
      if (urlParamTableState) {
        urlParamTableState = JSON.parse(
          LZString.decompressFromEncodedURIComponent(urlParamTableState)
        );

        let urlParamColumnsByName = this.convertToObjectById(
          urlParamTableState.columns,
          "name"
        );

        if (
          Object.keys(urlParamColumnsByName).every(urlParamColumnName =>
            localColumnNames.includes(urlParamColumnName)
          )
        ) {
          this.searchFilter = urlParamTableState.searchFilter;
          this.localPagination = urlParamTableState.pagination;

          this.localColumns = this.localColumns.map(localColumn => {
            if (typeof urlParamColumnsByName[localColumn.name] === "undefined")
              return localColumn;

            if (this.customizable) {
              localColumn.visible =
                urlParamColumnsByName[localColumn.name].visible;
            }

            if (
              this.filterable &&
              urlParamColumnsByName[localColumn.name].filterValues
            ) {
              Object.keys(
                urlParamColumnsByName[localColumn.name].filterValues
              ).forEach(filterValueOperator => {
                localColumn.filterValues[filterValueOperator] =
                  urlParamColumnsByName[localColumn.name].filterValues[
                    filterValueOperator
                  ];
              });
              localColumn.activeFilterCount = Object.values(
                localColumn.filterValues
              ).reduce(
                (partialCount, filterValue) =>
                  partialCount +
                  (Array.isArray(filterValue)
                    ? filterValue.length
                    : filterValue.length > 0
                    ? 1
                    : 0),
                0
              );
            }

            return localColumn;
          });
        }
      }

      // If pivot table, move "dimension" columns to the front
      if (this.pivotTable) {
        this.localColumns.sort((a, b) => ("" + a.type).localeCompare(b.type));
      }

      // Initialize filters (by column)
      this.initializeFiltersByColumn();
      this.applyFiltersByColumn();
    },
    saveState() {
      localStorage.setItem(
        this.tableKey + "_localColumns",
        JSON.stringify(this.localColumns)
      );

      if (!this.disableState) {
        let urlParams = new URLSearchParams(window.location.search);
        /*urlParams.set(
          this.tableKey + "_state",
          window.btoa(JSON.stringify(this.localColumns))
        );*/
        urlParams.set(
          this.tableKey + "_state",
          LZString.compressToEncodedURIComponent(
            JSON.stringify({
              columns: this.localColumns.map(localColumn => ({
                name: localColumn.name,
                visible: localColumn.visible,
                filterValues: Object.keys(localColumn.filterValues)
                  .filter(k => localColumn.filterValues[k].length > 0)
                  .reduce(
                    (a, k) => ({ ...a, [k]: localColumn.filterValues[k] }),
                    {}
                  )
              })),
              pagination: this.localPagination,
              searchFilter: this.searchFilter
            })
          )
        );

        window.history.replaceState(
          history.state,
          "",
          "?" + urlParams.toString()
        );
      }
    },
    handleQTableRequest(props) {
      this.localPagination.page = props.pagination.page;
      this.localPagination.rowsPerPage = props.pagination.rowsPerPage;
      this.localPagination.sortBy = props.pagination.sortBy;
      this.localPagination.descending = props.pagination.descending;

      this.triggerUpdateRowsEvent();
    },
    resetRows() {
      this.localRows = this.rows;
    },
    resetFilters() {
      this.localColumns = this.localColumns.map(localColumn => {
        localColumn.activeFilterCount = 0;
        localColumn.filterValues = {
          in: [],
          notIn: [],
          gt: "",
          lt: ""
        };
        return localColumn;
      });
      this.initializeFiltersByColumn();
    },
    resetColumnFilters(localColumn) {
      this.$emit("filterReset");

      if (localColumn.type === "dimension") {
        localColumn.filterValues.in = [];
        this.filtersByColumn[localColumn.name].dimensionFilterOptionsSearch =
          "";
        this.filtersByColumn[
          localColumn.name
        ].dimensionFilterOptions = this.filtersByColumn[
          localColumn.name
        ].dimensionFilterOptions.map(tempFilterOption => {
          tempFilterOption.visible = true;
          return tempFilterOption;
        });
      } else if (localColumn.type === "metric") {
        localColumn.filterValues.gt = "";
        localColumn.filterValues.lt = "";
      }
      this.applyFiltersByColumn();
    },
    initializeFiltersByColumn() {
      // Loop through current filterByColumns and get the dimensionFilterOptionsSearch for each column. We'll then reference this when filtering the dimensionFilterOptions.
      const dimensionFilterOptionsSearchByColumn = {};
      Object.keys(this.filtersByColumn).forEach(columnName => {
        dimensionFilterOptionsSearchByColumn[columnName] = {
          dimensionFilterOptionsSearch: this.filtersByColumn[columnName]
            .dimensionFilterOptionsSearch
        };
      });
      this.filtersByColumn = this.localColumns.reduce(
        (filtersByColumn, localColumn) =>
          Object.assign(
            filtersByColumn,
            localColumn.type === "dimension"
              ? {
                  [localColumn.name]: {
                    filterContentExpanded: false,
                    dimensionFilterOptions: !localColumn.filterable
                      ? []
                      : this.serverSide &&
                        Object.keys(this.dimensionFilterOptionsByColumn)
                          .length > 0 &&
                        this.dimensionFilterOptionsByColumn[localColumn.name]
                      ? this.dimensionFilterOptionsByColumn[
                          localColumn.name
                        ].map(filterOptions => ({
                          label: filterOptions.label,
                          value: filterOptions.value,
                          visible: true
                        }))
                      : this.serverSide
                      ? []
                      : this.$_.uniqBy(this.localRows, localRowA =>
                          this.extractColumnFieldValueFromRow(
                            localRowA,
                            localColumn
                          )
                        )
                          .map(localRowB => ({
                            label: this.extractColumnFieldValueFromRow(
                              localRowB,
                              localColumn,
                              true
                            ),
                            value: this.extractColumnFieldValueFromRow(
                              localRowB,
                              localColumn
                            ),
                            visible: true
                          }))
                          .sort((a, b) => localColumn.sort(a.value, b.value)),
                    dimensionFilterOptionsSearch: dimensionFilterOptionsSearchByColumn[
                      localColumn.name
                    ]
                      ? dimensionFilterOptionsSearchByColumn[localColumn.name]
                          .dimensionFilterOptionsSearch
                      : ""
                  }
                }
              : {
                  [localColumn.name]: {
                    filterContentExpanded: false
                  }
                }
          ),
        {}
      );
      this.refreshActiveColumnFilters();
    },
    applyFiltersByColumn() {
      this.internalLoading = true;

      this.$emit("filtersApplied");

      // Set all localColumns.activeFilterCount properties.
      this.localColumns = this.localColumns.map(mappedLocalColumn => {
        mappedLocalColumn.activeFilterCount = Object.values(
          mappedLocalColumn.filterValues
        ).reduce(
          (partialCount, filterValue) =>
            partialCount +
            (Array.isArray(filterValue)
              ? filterValue.length
              : filterValue.length > 0
              ? 1
              : 0),
          0
        );
        return mappedLocalColumn;
      });

      // If server-side, then do not perform the client-side filtering.
      if (!this.serverSide) {
        this.resetRows();
        // If no columns have active filters, then we'll just be reseting the rows and returning.
        // If we have active filters, search for rows that match our filter values, and update localRows accordingly.
        if (this.numColumnsWithActiveFilters > 0) {
          let activeFilteredLocalColumns = this.localColumns.filter(
            tempLocalColumn => tempLocalColumn.activeFilterCount > 0
          );

          this.localRows = this.localRows.filter(localRow => {
            let filterMatchFound = true;

            activeFilteredLocalColumns.some(localColumn => {
              Object.entries(localColumn.filterValues).some(filter => {
                let filterOperator = filter[0];
                let filterValue = filter[1];

                // Filter is not active, so skip.
                if (filterValue.length === 0) return false;

                let localRowValue = this.extractColumnFieldValueFromRow(
                  localRow,
                  localColumn
                );

                if (localColumn.type === "dimension") {
                  if (filterOperator === "in") {
                    if (!filterValue.includes(localRowValue)) {
                      filterMatchFound = false;
                      return true;
                    }
                  }
                } else if (localColumn.type === "metric") {
                  if (localRowValue === "" || localRowValue === null) {
                    filterMatchFound = false;
                    return true;
                  }
                  if (localColumn.metricType === "date") {
                    filterValue = Date.parse(
                      filterValue.toString() +
                        (filterOperator === "gt" ? " 23:59:59" : " 00:00:00")
                    );
                    localRowValue = Date.parse(localRowValue.toString());
                  }
                  if (filterOperator === "gt") {
                    if (
                      parseFloat(localRowValue.toString()) <=
                      parseFloat(filterValue.toString())
                    ) {
                      filterMatchFound = false;
                      return true;
                    }
                  } else if (filterOperator === "lt") {
                    if (
                      parseFloat(localRowValue.toString()) >=
                      parseFloat(filterValue.toString())
                    ) {
                      filterMatchFound = false;
                      return true;
                    }
                  }
                }
              });
              return !filterMatchFound;
            });

            return filterMatchFound;
          });
        }
      }

      this.internalLoading = false;
    },
    generateColumnFilterPillLabel(localColumn) {
      let label = localColumn.activeFilterCount + " Selected";

      if (localColumn.type === "metric") {
        if (localColumn.metricType === "date") {
          label =
            localColumn.activeFilterCount == 2
              ? this.$dayjs(localColumn.filterValues.gt, "YYYY-MM-DD").format(
                  "ll"
                ) +
                " - " +
                this.$dayjs(localColumn.filterValues.lt, "YYYY-MM-DD").format(
                  "ll"
                )
              : (localColumn.filterValues.gt.length > 0
                  ? "After " +
                    this.$dayjs(
                      localColumn.filterValues.gt,
                      "YYYY-MM-DD"
                    ).format("ll")
                  : "") +
                (localColumn.filterValues.lt.length > 0
                  ? "Before " +
                    this.$dayjs(
                      localColumn.filterValues.lt,
                      "YYYY-MM-DD"
                    ).format("ll")
                  : "");
        } else if (localColumn.metricType === "number") {
          label =
            localColumn.activeFilterCount == 2
              ? "Between " +
                this.numberFormatter(localColumn.filterValues.gt) +
                " and " +
                this.numberFormatter(localColumn.filterValues.lt)
              : (localColumn.filterValues.gt.length > 0
                  ? "Greater Than " +
                    this.numberFormatter(localColumn.filterValues.gt)
                  : "") +
                (localColumn.filterValues.lt.length > 0
                  ? "Less Than " +
                    this.numberFormatter(localColumn.filterValues.lt)
                  : "");
        } else if (localColumn.metricType === "year") {
          label =
            localColumn.activeFilterCount == 2
              ? "Between " +
                localColumn.filterValues.gt +
                " and " +
                localColumn.filterValues.lt
              : (localColumn.filterValues.gt.length > 0
                  ? "Greater Than " + localColumn.filterValues.gt
                  : "") +
                (localColumn.filterValues.lt.length > 0
                  ? "Less Than " + localColumn.filterValues.lt
                  : "");
        }
      }

      return label;
    },
    toggleSidePanel() {
      this.sidePanel.show = !this.sidePanel.show;
    },
    extractColumnFieldValueFromRow(row, column, formatted = false) {
      let value =
        typeof column.field === "function"
          ? column.field(row)
          : row[column.field === void 0 ? column.name : column.field];
      value = value === null ? (column.type === "dimension" ? "" : 0) : value;
      return formatted
        ? column.format !== void 0
          ? column.format(value)
          : value
        : value;
    },
    exportTable() {
      const content = [
        this.localColumns.map(col => this.wrapCsvValue(col.label))
      ]
        .concat(
          this.localRows.map(row =>
            this.localColumns
              .map(col =>
                this.wrapCsvValue(
                  typeof col.field === "function"
                    ? col.field(row)
                    : row[col.field === void 0 ? col.name : col.field],
                  col.format
                )
              )
              .join(",")
          )
        )
        .join("\r\n");
      return exportFile(
        (this.title.length > 0 ? this.title : "table_export") +
          "-" +
          this.$dayjs()
            .tz()
            .format("lll"),
        content,
        "text/csv"
      );
    },
    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val;
      formatted =
        formatted === void 0 || formatted === null ? "" : String(formatted);
      formatted = formatted.split('"').join('""');
      return `"${formatted}"`;
    },
    refreshActiveColumnFilters() {
      // Let's re-filter any columns with a value in the dimensionFilterOptionsSearch property.
      this.localColumns = this.localColumns.map(localColumn => {
        if (
          localColumn.type === "dimension" &&
          this.filtersByColumn[localColumn.name].dimensionFilterOptionsSearch
        ) {
          this.filtersByColumn[
            localColumn.name
          ].dimensionFilterOptions = this.filtersByColumn[
            localColumn.name
          ].dimensionFilterOptions.map(filterOption => {
            filterOption.visible =
              this.filtersByColumn[localColumn.name]
                .dimensionFilterOptionsSearch === "" ||
              filterOption.value
                .toString()
                .toLowerCase()
                .includes(
                  this.filtersByColumn[
                    localColumn.name
                  ].dimensionFilterOptionsSearch
                    .toString()
                    .toLowerCase()
                ) ||
              filterOption.label
                .toString()
                .toLowerCase()
                .includes(
                  this.filtersByColumn[
                    localColumn.name
                  ].dimensionFilterOptionsSearch
                    .toString()
                    .toLowerCase()
                );
            return filterOption;
          });
        }
        return localColumn;
      });
    }
  }
};
</script>

<style lang="scss">
.sticky-header-table thead,
.sticky-header-table thead tr,
.sticky-header-table thead tr th {
  position: sticky;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.sticky-header-table thead,
.sticky-header-table thead tr.datatable-column-headers,
.sticky-header-table thead tr.datatable-column-headers th {
  top: 0;
  background-color: #fff;
}

.sticky-header-table tbody tr.totals-row td {
  position: sticky;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-weight: 500;
  font-size: 0.85rem;
}

.sticky-header-table tbody tr.totals-row:last-child td {
  bottom: 0;
  background-color: #fff;
}

.q-table--loading .q-table__bottom--nodata {
  display: none;
}

.datatable {
  &.--max-height-disabled {
    max-height: none !important;
  }

  td {
    /* a {
      font-weight: 500;
    } */
  }

  tbody tr.totals-row td {
    font-weight: 500;
    font-size: 0.85rem;
  }

  &.--sticky-column-table {
    position: relative;
    z-index: 1;

    thead tr:first-child th:first-child, td:first-child {
      position: sticky;
      left: 0;
      border-right: 1px solid color(border, light);
    }

    thead tr:first-child th:first-child {
      background-color: #fff;
      opacity: 1;
      z-index: 2;
    }

    td:first-child {
      background-color: #fff;
      z-index: 1;
    }

    thead, thead tr:first-child, tbody tr.totals-row td:first-child {
      z-index: 2;
    }

  }
}
</style>
